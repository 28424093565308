import PropTypes from "prop-types";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import IconBack from "../../images/icon_back.png";
import IconReset from "../../images/icon_reset.png";
import IconExport from "../../images/icon_export_excel.gif";

import Layout from "../layout";
import { IconLink } from "./download-common";
import { DefDatePicker, DefMonthPicker, DefYearPicker } from "../datepicker";
import { Radio } from "../input";
import { ErrMsg } from "../message";
import { LamppostService } from "../../services/lamppost";
import {
    isDatePeriod,
    isMonthPeriod,
    isYearPeriod,
    periodFormat,
    currentYear as defDate,
    PeriodTypes,
    Hourly,
    dateToUnixTimestamp,
    formatDate,
    displayPeriodFormat,
    newDateFromUnixTime,
    getPeriodRange,
    subtractSecond,
    asDateInHKT,
    checkPeriodDuration,
} from "../../utils/time";
import Metrics from "../../utils/lammpost-metrics";
import { windowGtagEvent } from "../../utils/gtag";
import { useSettings } from "../../hooks/settings";

const DownloadByParam = ({ lang, path }) => (
    <Layout lang={lang} path={path} i18nSEO="DataDownloadByParam">
        <View lang={lang} />
    </Layout>
);
DownloadByParam.propTypes = {
    lang: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};

export default DownloadByParam;

const View = ({ lang }) => {
    const defLamppostIds = () => ["", "", "", "", "", ""];
    const { t } = useTranslation();
    const lamppostService = LamppostService({ apiUrl: useSettings().apiUrl });

    const [lampposts, setLampposts] = useState([]);
    const [lamppostIds, setLamppostIds] = useState(defLamppostIds());
    const [pollutant, setPollutant] = useState(Metrics.NO);
    const [period, setPeriod] = useState(Hourly);
    const [paramErr, setParamErr] = useState("");
    const [timeErr, setTimeErr] = useState("");
    const [deviceErr, setDeviceErr] = useState("");

    const [timeFrom, setTimeFrom] = useState(defDate());
    const [timeTo, setTimeTo] = useState(defDate());
    const [timeFromMon, setTimeFromMon] = useState(defDate());
    const [timeToMon, setTimeToMon] = useState(defDate());
    const [timeFromYear, setTimeFromYear] = useState(defDate());
    const [timeToYear, setTimeToYear] = useState(defDate());

    const updateLamppostIds = (e, idx) => {
        let newIds = lamppostIds.map(l => l);
        newIds[idx] = e.target.value;
        setLamppostIds(newIds);
    };

    const isDate = () => isDatePeriod(period);
    const isMonth = () => isMonthPeriod(period);
    const isYear = () => isYearPeriod(period);

    const dateFormat = () => {
        const format = periodFormat(period);
        return format !== "" ? t(format) : format;
    };

    const reset = () => {
        setParamErr("");
        setTimeErr("");
        setDeviceErr("");
        const date = defDate();
        setTimeFrom(date);
        setTimeFromMon(date);
        setTimeFromYear(date);
        setTimeTo(date);
        setTimeToMon(date);
        setTimeToYear(date);
        setLamppostIds(defLamppostIds());
    };

    const download = () => {
        isDate()
            ? checkDownload(timeFrom, timeTo)
            : isMonth()
            ? checkDownload(timeFromMon, timeToMon)
            : isYear() && checkDownload(timeFromYear, timeToYear);
    };

    const checkDownload = (localFrom, localTo) => {
        const { from, to } = getPeriodRange(
            asDateInHKT(localFrom),
            asDateInHKT(localTo),
            period
        );
        let hasErr = false;
        setParamErr("");
        setTimeErr("");
        setDeviceErr("");

        if (!pollutant) {
            setParamErr(t("SelectParam"));
            hasErr = true;
        }
        if (from > to) {
            setTimeErr(
                t("PeriodHint", {
                    format: dateFormat(),
                })
            );
            hasErr = true;
        } else {
            const errCode = checkPeriodDuration(from, to, period);
            if (errCode) {
                setTimeErr(t(errCode));
                hasErr = true;
            }
        }
        let devices = lamppostIds.filter(id => id !== "");
        if (devices.length === 0) {
            setDeviceErr(t("DeviceHint"));
            hasErr = true;
        }
        if (hasErr) {
            return;
        }
        const params = {
            pollutant,
            type: period,
            from: dateToUnixTimestamp(from),
            to: dateToUnixTimestamp(to),
            lamppostIds: devices.reduce(
                (acc, cur) => {
                    if (!acc.idxMap[cur]) {
                        acc.idxMap[cur] = true;
                        acc.vals.push(cur);
                    }
                    return acc;
                },
                { vals: [], idxMap: {} }
            ).vals,
            lang,
        };
        lamppostService.probeMetricsByPollutant(params).then(d => {
            if (d.error) {
                setTimeErr(d.error.message || d.error.code);
            } else if (!d.min_observation_time || !d.max_observation_time) {
                setTimeErr(t("NoDataAvail"));
            } else if (
                d.min_observation_time <= params.from &&
                params.to <= d.max_observation_time
            ) {
                windowGtagEvent(
                    "download",
                    "download_by_parameter",
                    `${pollutant}_${lang}`
                );
                lamppostService.downloadMetricsByPollutant(params);
            } else {
                setTimeErr(
                    t("NotWithinDateRange", {
                        from: formatDate(
                            newDateFromUnixTime(d.min_observation_time),
                            displayPeriodFormat(period)
                        ),
                        to: formatDate(
                            newDateFromUnixTime(
                                subtractSecond(d.max_observation_time)
                            ),
                            displayPeriodFormat(period)
                        ),
                    })
                );
            }
        });
    };

    const periodView = (
        DatePicker,
        datePickerIdPrefix,
        timeFrom,
        setTimeFrom,
        timeTo,
        setTimeTo,
        visible
    ) =>
        visible && (
            <>
                <div>
                    <div className="labelPeriod">
                        <label htmlFor={`${datePickerIdPrefix}From`}>
                            {t("From")}: ({dateFormat()})
                        </label>
                    </div>
                    <div className="mb3 inline-block">
                        <DatePicker
                            id={`${datePickerIdPrefix}From`}
                            selected={timeFrom}
                            onChange={d => d && setTimeFrom(d)}
                            lang={lang}
                        />
                    </div>
                </div>
                <div>
                    <div className="labelPeriod">
                        <label htmlFor={`${datePickerIdPrefix}To`}>
                            {t("To")}: ({dateFormat()})
                        </label>
                    </div>
                    <div className="mb3 inline-block">
                        <DatePicker
                            id={`${datePickerIdPrefix}To`}
                            selected={timeTo}
                            onChange={d => d && setTimeTo(d)}
                            lang={lang}
                        />
                    </div>
                </div>
            </>
        );

    const DeviceOption = ({ lamppostId, i }) => (
        <div className="devOptions">
            <div className="flexrow-center mb2">
                <div style={{ width: "35%" }}>
                    <label htmlFor={`selDistrict${i + 1}`}>
                        {t(`Device${i + 1}`)}
                    </label>
                </div>
                <div style={{ width: "50%" }}>
                    <select
                        id={`selDistrict${i + 1}`}
                        style={{ width: "100%" }}
                        key={i}
                        value={lamppostId}
                        onChange={e => updateLamppostIds(e, i)}
                        onBlur={() => {}}
                    >
                        <option value="">--- {t("Select")} ---</option>
                        {lampposts.length !== 0 &&
                            lampposts.map((l, idx) => {
                                return (
                                    <option key={idx} value={l.lamppost_id}>
                                        {l["location_" + lang]}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        lamppostService
            .getLampposts()
            .then(d => setLampposts(d.lampposts || []))
            .catch(err => console.error(err));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="sections">
            <div className="header">
                <div className="title">{t("DataDownloadByParam")}</div>
                <div className="links">
                    <IconLink
                        to={`/${lang}/download/devices`}
                        label={t("DataDownloadByDevice")}
                    />
                    <IconLink
                        to={`/${lang}/download/year`}
                        label={t("DataDownloadByYear")}
                    />
                </div>
            </div>

            <div className="section">
                <div>1. {t("SelectParam")}</div>
                <div>
                    <fieldset>
                        <legend>{t("SelectParam")}</legend>
                        <Radio
                            name="pollutant"
                            checked={pollutant === Metrics.NO2}
                            onChange={() => setPollutant(Metrics.NO2)}
                        >
                            {t("No2Name")}
                        </Radio>
                        <Radio
                            name="pollutant"
                            checked={pollutant === Metrics.NOX}
                            onChange={() => setPollutant(Metrics.NOX)}
                        >
                            {t("NOX")}
                        </Radio>
                        <Radio
                            name="pollutant"
                            checked={pollutant === Metrics.PM25}
                            onChange={() => setPollutant(Metrics.PM25)}
                        >
                            <span
                                dangerouslySetInnerHTML={{ __html: t("PM25") }}
                            ></span>
                        </Radio>
                        <ErrMsg msg={paramErr} />
                    </fieldset>
                </div>
            </div>

            <div className="section">
                <div>2. {t("SelectDevices")}</div>
                <div>
                    {lamppostIds.map((lamppostId, i) => (
                        <DeviceOption key={i} lamppostId={lamppostId} i={i} />
                    ))}
                    <ErrMsg msg={deviceErr} />
                </div>
            </div>

            <div className="section">
                <div>3. {t("SelectAvgPeriod")}</div>
                <div>
                    <fieldset>
                        <legend>{t("SelectAvgPeriod")}</legend>
                        {PeriodTypes.map((p, idx) => {
                            return (
                                <Radio
                                    key={idx}
                                    name="period"
                                    checked={period === p}
                                    onChange={() => {
                                        setTimeErr("");
                                        setPeriod(p);
                                    }}
                                >
                                    {t(p)}
                                </Radio>
                            );
                        })}
                    </fieldset>
                </div>
            </div>

            <div className="section">
                <div>4. {t("SelectPeriod")}</div>
                <div>
                    {periodView(
                        DefDatePicker,
                        "dpDatePeriod",
                        timeFrom,
                        setTimeFrom,
                        timeTo,
                        setTimeTo,
                        isDate()
                    )}
                    {periodView(
                        DefMonthPicker,
                        "dpMonthPeriod",
                        timeFromMon,
                        setTimeFromMon,
                        timeToMon,
                        setTimeToMon,
                        isMonth()
                    )}
                    {periodView(
                        DefYearPicker,
                        "dpYearPeriod",
                        timeFromYear,
                        setTimeFromYear,
                        timeToYear,
                        setTimeToYear,
                        isYear()
                    )}
                    <ErrMsg msg={timeErr} />
                </div>
            </div>

            <div className="mt6 flexrow-center">
                <Link
                    className="btnIcon mr3"
                    aria-label={t("Back")}
                    to={`/${lang}/download`}
                >
                    <img src={IconBack} alt="" />
                    <div>{t("Back")}</div>
                </Link>
                <button
                    className="btnIcon mr3"
                    onClick={reset}
                    aria-label={t("Reset")}
                >
                    <img src={IconReset} alt="" />
                    <div>{t("Reset")}</div>
                </button>
                <button
                    className="btnIcon"
                    onClick={download}
                    aria-label={t("DownloadCsv")}
                >
                    <img src={IconExport} alt="" />
                    <div>{t("DownloadCsv")}</div>
                </button>
            </div>

            <div className="footnote">{t("EPDHK")}</div>
        </div>
    );
};
